import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class ContestVoteSubjectService {

  myVotesInit : number = 0
  
  private myVotesSubject = new BehaviorSubject<any>(this.myVotesInit)
  public myVotes : Observable<any> = this.myVotesSubject.asObservable()

  constructor(
  ) {}

  renewMyVotes( newVotes : number ) {
    this.myVotesSubject.next( newVotes )
  }

}