
<a class="create-group-btn-container" (click)="openModal()">
  <span class="create-group-btn"> 
    Create Group
    <span class="create-grp-plus">+</span>
  </span>
</a>
<share-modal *ngIf="showShareModal" [mediaObject]="group" [mediaObjectType]="'group'" (toggleClose)="closedShareModal($event)"></share-modal>
<div id="uploadGroupModal" class="cssModal" *ngIf="showModal" style="display: block;">
    <div id="uploadGroupModalContent" class="cssModalContent modal-content row" style="display: block;
    font-size: initial;
    text-align: center;
    line-height: initial;">
      <div>
        <div class="modal-header ">
          <button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
          <h2>Create Group</h2>
        </div>
        <div *ngIf="showUploadModal">
          <h2>Upload a Group Profile Picture</h2>
          <upload (uploadFinished)="onUploadFinished($event)" [uploadType]="'picture'" [competitionEntryOrGroupPicture]="'groupPicture'" [objectId]="group._id" [maxFiles]="1" ></upload>
          <a (click)="skipUpload()">Skip Upload</a>
        </div>
        <div *ngIf="!submittingGroupToServer && !submittedGroupToServer">
          <div>
            <form [formGroup]="createGroupForm" autocomplete="off" novalidate (ngSubmit)="createGroup(createGroupForm.value)">
              <div class="col-xs-12" [ngClass]="{ 'error' : createGroupForm.controls.groupName.invalid && createGroupForm.controls.groupName.touched }">
                <label>Group Name</label>
                <input class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="groupName" id="groupName" type="text" placeholder="group name">
                <p class="error-msg" *ngIf="createGroupForm.controls.groupName.invalid && createGroupForm.controls.groupName.errors.required && createGroupForm.controls.groupName.touched">required</p>
              </div>
              <div class="col-xs-12" [ngClass]="{ 'error' : createGroupForm.controls.groupUrl.invalid && createGroupForm.controls.groupUrl.touched }">
                <label>Group Handle</label>
                <div class="col-xs-12">
                  <span>@</span><input class="roundBorders dark-purple-background input-styling" formControlName="groupUrl" id="groupUrl" type="text" placeholder="where people find you">
                </div>
                <p class="error-msg" *ngIf="createGroupForm.controls.groupUrl.invalid && createGroupForm.controls.groupUrl.errors.required && createGroupForm.controls.groupUrl.touched" >Required</p>
              </div>
              <div class="col-xs-12">
                <label>Hashtags (separated by a space)</label><input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="tagString" [ngModelOptions]="{standalone : true}" id="tags" type="text" placeholder="#sports #science">
                <div *ngIf="tagsMustStartWithHash">
                  <p>Tags must start with a '#'</p>
                </div>
              </div>
              <div class="col-xs-12">
                <input class="btn btn-info btn-lg" type="submit" value="Create Group">
              </div>
            </form>
          </div>
        </div>

        <div class="col-xs-12">
          <div class="col-xs-offset-1 medium-text" *ngIf="submittingGroupToServer"><p>Submitting group, one moment please.</p></div>
          <div class="col-xs-offset-1 medium-text" *ngIf="errorSubmittingGroupToServer"><p> {{ err }} </p></div>
        </div>
        </div>
    </div>
</div>