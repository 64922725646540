import { Component , Input , OnInit , EventEmitter , NgZone } from '@angular/core'
import { DataService , SessionService , UserDataService } from '../../core/index'

import { UploadOutput, UploadInput, UploadFile, humanizeBytes , UploadProgress } from 'ngx-uploader';

import { IContest , IFan , IGroup, IContestEntry } from '../../shared/interfaces'

import { InternationalService } from '../../shared/international.service'
import { stripePublishableKey } from '../../shared/keys/keys'
import { zeroDecimalCurrencies } from '../../shared/international/zeroDecimalCurrencies'

import moment from 'moment' 
import 'moment-timezone'

@Component({
  selector: 'upload-contest-entries', 
  templateUrl: "./uploadContestEntries.html"
})

export class UploadContestEntriesComponent {    

  uploadOptions : any
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  modalId : string

  URL : string = 'api/contestEntry/'

  @Input() myFan : IFan | boolean
  @Input() contest : any
  @Input() group : any
  contestEntry : IContestEntry

  tip : any = 0
  maxUploads : number 
  submitting : boolean
  percentUploaded : any
  session : any
  err : string
  successSubmittingContestEntryToServer : boolean = false
  alreadyEnteredContest : boolean = false
  userLoaded : boolean = false
  fanLoaded : boolean = false
  fanNeedsApproval : boolean = false 
  contestEntryLoaded : boolean = false
  finishedUploading : boolean
  showUpdateProfile : boolean

  showCommentOnEntry : boolean

  showShareModal : boolean = false 
  registrationText : string = 'Enter'
  priceToBecomeKindOfFan : number = 0
  entryPrice : number 
  userData : any 
  userNotSignedIn : boolean = true
  showSignup : boolean = true  
  showSignin : boolean = false 
  contestStarted : boolean

  constructor(
    private dataService : DataService ,
    private sessionService : SessionService ,
    private internationalService : InternationalService , 
    private userDataService : UserDataService , 
    private zone : NgZone
  ) {}

  ngOnInit() {
    this.modalId = 'uploadContestEntryModal' + this.contest._id
    this.sessionService.session.subscribe( (session : any ) => {
      this.session = session
      this.setEntryPrice()
      this.setRegistrationText()
      if (session.loggedIn !== true) {
        this.showSignin = false 
        this.showSignup = true
        return this.userNotSignedIn = true
      } else {
        this.getMyFan(this.contest.groupUrl)
        this.selectContest()
        this.userNotSignedIn = false
      } 
    })
    this.getMyUser()
    this.userLoaded = true
    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.userData = userData
       let myContestEntry = userData.myContestEntries.filter(( ce : IContestEntry ) => {
        return ( ce.contestId === this.contest._id )
      })[0]
      this.contestEntry = myContestEntry
      let mySubmissions = ( myContestEntry ) ? 
        myContestEntry.entryAttachments ? myContestEntry.entryAttachments.length : 
        myContestEntry.entryAttachment ? 1 : 0 : 0
      this.maxUploads = this.contest.maxSubmissionsPerEntry - mySubmissions
    })
    this.getPriceToBecomeAKindOfFan()
  }

  setRegistrationText() {
    this.registrationText = (this.contest.earlyRegistration && moment().isBefore(this.contest.earlyRegistration.registrationTime)) ?
      "Register" 
      :
      (this.contest.registration && moment().isBefore(this.contest.registration.registrationTime)) ?
        "Register"
        :
        (this.contest.lateRegistration && moment().isBefore(this.contest.lateRegistration.registrationTime)) ?
          "Register"
          :
          "Enter"
    this.contestStarted = (!this.contest.startTime) ? true : moment().isAfter(this.contest.startTime)
    this.registrationText = ( this.contest.startTime && moment().isBefore(this.contest.startTime) ) ?
      this.registrationText
      :
      "Enter"
  }


  openModal() {
    document.getElementById('uploadContestEntryModal' + this.contest._id ).style.display = "block"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id ).style.display = "block"
  }

  closeModal() {
    document.getElementById('uploadContestEntryModal' + this.contest._id ).style.display = "none"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id ).style.display = "none"
  }

  getMyUser() {
    let URI = 'user/getUserBySession'
    this.dataService.getObject( URI )
      .subscribe( ( response : any ) => {
        if ( response.error || !response.user ) 
          return 
        let user = response.user
        // this.showUpdateProfile = ( !user.twitterLink && !user.facebookLink && !user.instagramLink )
      })
  }

  closeUpdateProfileModal( closed : any ) {
    this.showUpdateProfile = false 
  }


  setEntryPrice( ) {
    let hasEarlyRegistrationFee = this.contest.earlyRegistration && moment().isBefore(this.contest.earlyRegistration.registrationTime) &&  (this.contest.earlyRegistration.registrationPrice > 0) ,
      hasRegistrationFee = this.contest.registration && moment().isBefore(this.contest.registration.registrationTime) &&  (this.contest.registration.registrationPrice > 0) ,
      hasLateRegistrationFee = this.contest.lateRegistration && moment().isBefore(this.contest.lateRegistration.registrationTime) &&  (this.contest.lateRegistration.registrationPrice > 0)
    this.entryPrice = this.contest.contestPrice
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contest.currency )
      this.entryPrice = (this.entryPrice * this.session.exchangeRate / this.contest.exchangeRate)
  }

  getPriceToBecomeAKindOfFan() {
    if ( this.contest.kindOfFan ) {
      if (this.contest.kindOfFan !== 'fan') {
        this.priceToBecomeKindOfFan = this.group.kindsOfFans.filter(( kindOfFan : any ) => {
          return (kindOfFan.fanTitle === this.contest.kindOfFan)
        })[0].priceToBecomeKindOfFan
      } else {
        this.priceToBecomeKindOfFan = this.group.priceToBecomeFan
      }
    }
  }

  toggleNotSignedInPage() {
    this.showSignup = !this.showSignup
    this.showSignin = !this.showSignin
  }

  signedUserIn( notSignedUp : boolean ) {
    this.userNotSignedIn = notSignedUp 
  }

  becomeAFan() {
     
    let beAFanBody = {
      group : this.group ,
      groupUrl : this.group.groupUrl , 
      kindOfFan : this.contest.kindOfFan , 
      priceToBecomeFan : 0 
    }
    let URI = 'fan/becomeaFan'
    this.dataService.postObject(URI, beAFanBody)
      .subscribe((response) => {
        if (response.fan !== null) {
          if (response.fan.approved ) {
            if ( this.userData.myFans === null) {  
              this.userDataService.renewUserData({      
                createdGroups : this.userData.createdGroups ,
                myGroups : this.userData.myGroups ,
                myFans : [response.fan] , 
                myContests : this.userData.myContests ,
                myContestEntries : this.userData.myContestEntries
              })
            } else {     
              this.userData.myFans.push(response.fan)          
              this.userDataService.renewUserData({      
                createdGroups : this.userData.createdGroups ,
                myGroups : this.userData.myGroups ,
                myFans : this.userData.myFans , 
                myContests : this.userData.myContests ,
                myContestEntries : this.userData.myContestEntries
              })                    
            }
          } else {
            if ( this.userData.myFanRequests === null) {  
              this.userDataService.renewUserData({      
                createdGroups : this.userData.createdGroups ,
                myGroups : this.userData.myGroups ,
                myFanRequests : [response.fan] , 
                myContests : this.userData.myContests ,
                myContestEntries : this.userData.myContestEntries
              })
            } else {     
              this.userData.myFanRequests.push(response.fan)          
              this.userDataService.renewUserData({      
                createdGroups : this.userData.createdGroups ,
                myGroups : this.userData.myGroups ,
                myFanRequests : this.userData.myFanRequests , 
                myContests : this.userData.myContests ,
                myContestEntries : this.userData.myContestEntries
              })                    
            }
          }
        }
        this.err = null
        return this.registerForContest()
      })
  }
  getMyFan( url : string ) {
    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.myFan = userData.myFans.filter( ( fan : IFan ) => {
        return ( fan.groupUrl === url && fan.kindOfFan === this.contest.kindOfFan)
      })[0]
      if (!this.myFan)
        this.fanNeedsApproval = (userData.myFanRequests && userData.myFanRequests.filter( ( fan : IFan ) => {
          return ( fan.groupUrl === url && fan.kindOfFan === this.contest.kindOfFan)
        })[0]) ? true : false 
    })
  }

  selectContest() {
    let URI = 'contest/getContestById/' + this.contest._id
    this.dataService.getObject(URI)
      .subscribe((response) => {
        this.contest = response.contest
        this.selectContestEntry()
      })
  }

  selectContestEntry() {
    let URI = 'contestEntry/getMyContestEntryByContestantUsername'
    this.dataService.getObject(URI)
      .subscribe((response : any) => {
        this.contestEntryLoaded = true
        if (response.contestEntry !== null) {
          if (response.contestEntry.paid !== null)
            return this.alreadyEnteredContest = true
        }
        this.alreadyEnteredContest = false

      })
  }

  uploadContestEntryHandler() {
    if (this.userNotSignedIn)
      return this.openModal()
    if (this.contest.kindOfFan && !this.myFan)
      return this.becomeAFan()
    if ( this.contestEntry ) 
      if ( this.contestEntry.paid !== true ) 
        return this.processPayment( this.contestEntry )
      else
        return this.openModal()
    return this.registerForContest()
  }

  registerForContest() {
    this.sessionService.session.subscribe( (session:any) => {
      if (session.loggedIn !== true) 
        return this.userNotSignedIn = true
    })

    let URI = 'contestEntry/registerForContest/' + this.contest._id , formValues = {
      contestId : this.contest._id
    }

    this.dataService.postObject( URI , formValues )
      .subscribe( ( response : any ) => {
        console.log(response)
        if ( response.error )
          return this.err = response.error 
        let contestEntry = response.contestEntry
        if ( !(this.entryPrice > 0) || contestEntry.paid === true ) {
          this.userData.myContestEntries.push(contestEntry)
          this.userDataService.renewUserData(this.userData)
          if (moment().isAfter(this.contest.startTime) )
            this.openModal()
          return this.contestEntry = contestEntry
        }
        this.processPayment( contestEntry )
      })
  }


  onUploadFinished( newContestEntry : IContestEntry ) {
    if (this.entryPrice > 0 && newContestEntry.paid !== true )
      return this.processPayment( newContestEntry )

    this.contestEntry.entryAttachment = newContestEntry.entryAttachment
    let contestEntryToDelete = this.userData.myContestEntries.filter( ( ce ) => { return ce._id === newContestEntry._id })
    let indexOfEntryToDelete = this.userData.myContestEntries.indexOf( contestEntryToDelete )
    this.userData.myContestEntries.splice( indexOfEntryToDelete , 1 )
    this.userData.myContestEntries.push(newContestEntry)
    this.userDataService.renewUserData(this.userData)
    this.showCommentOnEntry = true
  }

  madeNewComment() {
    if ( this.contest.entryPrivacy !== "hidden")
      this.showShareModal = true     
    this.closeModal()
  }

  processPayment( contestEntry : any ) {

    if (this.entryPrice < 1 && this.entryPrice > 0)
      var stripeCharge = this.entryPrice * 100 + 3 + this.tip * 100 
    else 
      var stripeCharge =  this.entryPrice * 100 * 1.05 + this.tip * 100
    if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country) ) > -1 )
      stripeCharge = Math.ceil(stripeCharge / 100)
    var handler = (<any>window).StripeCheckout.configure({
      key: stripePublishableKey,
      locale: 'auto',
      currency : this.internationalService.getCurrencyCode(this.session.country) , 
      token: (token: any) => {
        var URL = 'contestEntry/updateContestEntryPaid'
        contestEntry.tip = this.tip
        contestEntry.contestPrice = this.entryPrice
        contestEntry.token = token
        contestEntry.group = this.group
        contestEntry.currency = this.internationalService.getCurrencyCode(this.session.country)
        this.dataService.postObject( URL, contestEntry )
          .subscribe(response => {
            if (response.error) {
              this.submitting = false 
              return this.err = response.error
            }
            
            this.userData.myContestEntries.push(response.newContestEntry)
            this.userDataService.renewUserData(this.userData)
            this.contestEntry = response.newContestEntry
            this.err = null
            this.submitting = false     
            if (moment().isAfter(this.contest.startTime) )
              this.openModal()
          })
      }
    });

    handler.open({
      name: this.contest.contestName ,
      description: 'enter contest' , 
      amount: stripeCharge
    });
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
  
  closedShareModal( showModal : boolean ) {
    this.showShareModal = false
  }

}