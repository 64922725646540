<div class="text-center" style="color : white ;">  
	<div style="position: fixed; background-image: url(/app/assets/images/login-background.jpg); background-repeat: no-repeat; background-position: center; background-size: cover; height: 100vh; width : 100vw;">
		
	</div>
	<div class="row" style="position: relative; background-color: rgba( 125 , 0 , 125 , 0.3);">
		<div class="col-sm-12 col-md-6">
			<div 
				style="
					margin-top :50px;
				"
			>
				<h1>Join Our Team to Help Everyone Live Their Dreams.</h1>
				<a routerLink="/mission">
					<h3 style="color: white;">Read our mission statement</h3>
				</a>
			</div>
		</div>
		<div class="col-sm-12 col-md-6">
			<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidolcom%20logo%20white.png?alt=media&token=1dc3985f-7450-4e70-a2a0-a182283d028c"	style="width: 100%; height : auto;">
		</div>
	</div>
	<div class="row" style="position: relative; background-color: white; color: black;">	
		<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3" style="text-align: left; margin-top : 15px; margin-bottom: 15px;">
			<h2 style=" margin : 3px; padding : 0px">Who We Are</h2>
			<p style="font-size : 20px; margin : 3px; padding : 0px">We are the defenders of the life of everyone's dreams. We unapologetically protect the most sacred moments of people's lives - their peak experiences. We understand the discipline it takes to serve thousands across 176 countries to live their dreams & move courageously to be their best. Does this sound like you? Join us!</p>
		</div>
	</div>
	<div class="row" style="position: relative; background-color: rgba(255 , 255 , 255 , 0.78); color: black;">	
		<div class="col-xs-12 col-sm-10 col-sm-offset-1">
			<h2>Our Professional Ideals</h2>
			<div class="row">
				<div class="col-xs-12 col-sm-4">
					<img src="/app/assets/images/biceps.png" style="height: 80px; width: auto; margin: 0px auto;" />
					<h3>Discipline</h3>
				</div>
				<div class="col-xs-12 col-sm-4">
					<img src="/app/assets/images/prayer.png" style="height: 80px; width: auto; margin: 0px auto;" />
					<h3>Respect</h3>
				</div>
				<div class="col-xs-12 col-sm-4">
					<img src="/app/assets/images/lion.png" style="height: 80px; width: auto; margin: 0px auto;" />
					<h3>Courage</h3>
				</div>
			</div>
		</div>
	</div>
	<div class="row" style="margin-bottom : 50px;">	
		<div class="col-xs-12 col-sm-10 col-sm-offset-1">
			<h2>Open Positions</h2>
			<div class="row">
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Chief Marketing Officer</h3>
						<p>Flexible Hours</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Chief Technical Officer</h3>
						<p>Flexible Hours</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Director of Sponsorship</h3>
						<p>Full Time</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Director of Platform</h3>
						<p>Full Time</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Lead Platform Developer</h3>
						<p>Full Time</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Lead Mobile Developer</h3>
						<p>Full Time</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>UX Designer</h3>
						<p>Flexible Hours</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
					<div class="position-info">
						<h3>Direct Marketing Associate</h3>
						<p>Part Time</p>
						<a href="mailto: support@spotidol.com">Learn More</a>
					</div>
				</div>
			</div>
			<h3>Want to work at Spotidol, but don't see a position that fits you? No problem! Contact us anyways at <a style="color: white;" href="mailto: support@spotidol.com">support@spotidol.com.</a></h3>
		</div>
	</div>
</div>
<footer></footer>