<div class="col-xs-12" style="color: white; margin : 0px; padding : 0px;">
	<div class="col-sm-12 text-align purple-gray-background white-text">
		<div class="col-md-6 col-sm-12 col-xs-12">
			<h1 class="hide-on-phones" style="font-size: 560% ; color: white ;">About Spotidol</h1>
			<h1 class="show-on-phones" style="font-size: 280%  ; color: white ;">About Spotidol</h1>
		</div>
		<div class="col-md-6 col-sm-12 col-xs-12" style="margin-top: 20px;">
			<h4>For commonly asked questions, check out <a routerLink="/faq">FAQ</a></h4>
			<h4>To contact support, check out <a routerLink="/support">support</a></h4>
			<h4>Spotidol is free to use. To view our pricing & features, check out <a routerLink="/pricing">pricing</a></h4>
		</div>
	</div>
	<div class="col-xs-12" style="height: 75vh; margin : 0px; padding : 0px; position: relative; background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fclouds.jpg?alt=media&token=739a8b62-18bd-49ce-96be-bc07a601c1c5); background-repeat: no-repeat; background-size: cover; background-position: center;">
		<div
			style="padding: 10px;" 
			class="image-shadow text-shadow"
		>
			<h1 style="font-size: 64px;">SPOTIDOL IS</h1>
			<h2 style="position: absolute; bottom: 20px; font-size : 24px; max-width: 600px;">A TECHNOLOGY COMPANY BUILDING PRODUCTS ENABLING EVERYONE TO HELP EACH OTHER LIVE THEIR DREAMS.</h2>
		</div>
	</div>
<!-- 	<div class="col-xs-12" style="margin-bottom: 20px;">
		<div style="height: 300px; margin: 20px auto ; border-radius: 6px; overflow: hidden; box-shadow: 0 0 0 1px rgba(255,255,255,.15), 0 2px 3px rgba(255,255,255,.2);">		
			<div class="col-xs-12 col-sm-6" style="background-color: #F5F5FF ; height: 100% ;">
				<p 
					style="
				    margin: 0 auto;
				    max-width: 300px;
				    color: black;
				    font-size: 20px;
				    transform: translateY(50%);
				    top: -50%;
			    "
	    	>
	    		Leveraging social, AI, gamification, & all of the cutting edge tech tools, we have a portfolio of products to craft the moments of people’s dreams.
	    	</p>
	    	<a routerLink="/portfolio">
	    		<div class="values-link">
	    			<p style="font-size: 20px;">View Our Portfolio...</p>
	    		</div>
	    	</a>
			</div>
			<div class="col-xs-12 col-sm-6" style="height: 100% ; background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fai.jpg?alt=media&token=8e436604-2c75-4ead-991e-f1ccb83a9e89); background-repeat: no-repeat; background-size: cover; background-position: center;">
			</div>
		</div>
	</div> -->
	<div class="col-xs-12" style=" margin : 0px; padding : 0px; height: 75vh; position: relative; background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fdreams.jpg?alt=media&token=491ba1dc-d46e-4afb-9e66-521702adc8bf); background-repeat: no-repeat; background-size: cover; background-position: center;">
		<div 
			style="padding: 10px;" 
			class="image-shadow-light text-shadow"
		>
			<h1 style="font-size: 64px;">SPOTIDOL'S MISSION</h1>
			<div style="position: absolute; bottom: 20px; font-size : 20px; max-width: 600px;">
				<h2>IS TO BUILD UP THE VALUE OF AS MANY IDOLS AS HIGH &amp; AS FAST AS POSSIBLE.</h2>
				<a routerLink="/mission"><p>Read Our Mission Statement	...</p></a>
			</div>
		</div>
	</div>
	<div class="col-xs-12">
		<h1 style="font-size: 64px;">OUR VALUES</h1>
		<div style="color: black;">
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container" >
					<p class="value-header-text">Monetization</p>			
					<div
						style="
					    height: 100px; 
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fmonetization.jpg?alt=media&token=82fe75b1-26ab-43c8-87c2-9e21d0e84154); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
					>
					</div>
					<p class="value-details-text">All people deserve a way of living.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container">
					<p class="value-header-text">Curation</p>			
					<div
						style="
					    background: red;
					    height: 100px;
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcuration.jpg?alt=media&token=5740b711-0a2b-436a-a8d9-53da4eac5f12); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
			    >
					</div>
					<p class="value-details-text">All people deserve to share their living with others.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container">
					<p class="value-header-text">Purpose</p>			
					<div
						style="
					    height: 100px;
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fpurpose.jpg?alt=media&token=a5b34b13-75d1-4604-ae5e-3e0a78ae7d8d); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
					>
					</div>
					<p class="value-details-text">All people deserve to live their dreams.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xs-12">
		<sign-up-redirect></sign-up-redirect>
	</div>
	<footer></footer>
</div>