import { Component , ViewChild , ElementRef , OnInit , Input , Output , EventEmitter ,  } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

import * as mime from 'mime-types'
import * as _ from "underscore" 

import { DataService } from '../../../core/index'

let pictureTypes    = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/apng', 'image/svg', 'image/bmp', 'image/bmp ico', 'image/png ico'] ,
  videoTypes    = ['video/mp4', 'video/webm', 'video/ogg' , 'video/msvideo' , 'video/avi' , 'video/quicktime'] ,
  audioTypes    = ['audio/mp3', 'audio/wav', 'audio/ogg'] ,
  pictureTypesString = 'image/jpeg, image/jpg, image/gif, image/png, image/apng, image/svg, image/bmp, image/bmp ico, image/png ico' , 
  videoTypesString = 'video/mp4, video/webm, video/ogg , video/msvideo , video/avi, video/quicktime' ,
  audioTypesString = 'audio/mp3, audio/wav, audio/ogg' 

@Component({
  selector: 'upload',
  templateUrl: './upload.html'
})

export class UploadComponent {

  @ViewChild('fileSelector')
  fileSelector : ElementRef

  @Input() competitionEntryOrGroupPicture : string = 'competitionEntry'
  @Input() objectId : string
  @Input() uploadType : string
  @Output() uploadFinished = new EventEmitter<boolean>()

  acceptedTypes : string[]
  acceptedTypesString : string = 'image/*'

  accessToken : string
  userId : string
  err : string 


  files : any[] = []
  file : any
  fileChunk : any
  fileType : string
  fileName : string
  fileExt : string
  filePath : string

  size : number
  sliceSize : number = 1024 * 1024 * 3
  totalSlices : number = 1
  currentslice : number = 1
  percentChanges : number = 0
  percentLeft : number = 0 

  cancelled : boolean = false 
  storageId : string
  uploadPath : string 
  start : number = 0
  end : number = 0
  fileStatuses : any[] = []
  // array = [{
  //   fileName : string ,
  //   fileStatus : string  
  //   'toUpload' | 'uploading' | 'processing' | 'uploaded'
  // }]


  loadedContent : boolean = false
  uploading : boolean = false 
  processing : boolean = false 
  uploaded : boolean = false 
  uploadsComplete : boolean = false

  fileIndex : number = 0
  currentFile : number = 1 
  @Input() maxFiles : number 

  constructor(
    private dataService : DataService , 
    private firebaseAuth : AngularFireAuth , 
    private storage : AngularFireStorage
  ) {}

  ngOnInit() {
    switch ( this.uploadType ) {
      case 'picture' :
        this.acceptedTypes = pictureTypes
        this.acceptedTypesString = 'image/*'
        break
      case 'video' : 
        this.acceptedTypes = videoTypes
        this.acceptedTypesString = 'video/*'
        break
      case 'audio' :
        this.acceptedTypes = audioTypes
        this.acceptedTypesString = 'audio/*'
        break
      case 'pdf' :
        this.acceptedTypes = ['application/pdf']
        this.acceptedTypesString = 'application/pdf'
        break
    }
  }

  ngAfterContentInit() { 
    this.loadedContent = true
  }

  onFileChange(event) {
    this.cancelled = false 
    this.err = ''
    let reader = new FileReader();
    
    if ( !event.target.files || event.target.files.length === 0 )
      return

    for ( var i = 0 ; i < this.files.length ; i++ ) {
      this.err = this.checkMimeType(this.files[i])
    }
    if ( this.err ) 
      return this.fileSelector.nativeElement.value = ""
    if ( ( event.target.files.length + this.files.length ) > this.maxFiles ) {
      this.err = 'You cannot upload more than ' + this.maxFiles + ' file' + (( this.maxFiles > 1 ) ? 's.' : '.')
      this.fileSelector.nativeElement.value = ""
    } 
    if ( this.files.length === 0 ) {
      let file = event.target.files[0]
      this.setFileProps( file , () => {
        this.files.push(...event.target.files)
        this.setFilesToUploadArray( event.target.files )
      })
    }
  }

  checkMimeType( file : any ) {
    switch (this.uploadType) {
      case "picture": {
        var compareAttachment = _.contains(pictureTypes, file.type); 
        if (compareAttachment === false) {
          return 'File must be a picture with type jpeg, gif, png, apng, svg, bmp, bmp ico, or png ico'
        }
        break
      }
      case "video": {
        var compareAttachment = _.contains(videoTypes, file.type);
        if (compareAttachment === false) {
          return 'file must be a video with type mov, mp4, webm, or ogg'
        }
        break
      }
      case "audio": {
        var compareAttachment = _.contains(audioTypes, file.type);
        if (compareAttachment === false) {
          return 'file must be an audio with type mp3, wav, or ogg'
        }
        break
      }
      case "pdf" : {
        if ( file.type !== 'application/pdf')
          return 'file must be a pdf with type .pdf'
      }
    }
    return null
  }

  setFileProps( file : any , callback ) {
    console.log( this.uploadType )
    this.percentLeft = 0
    this.currentslice = 1
    this.fileName = file.name 
    if ( this.uploadType !== 'picture' ) {
      this.file = file 
      this.fileName = file.name 
      this.fileType = file.type
      this.size = file.size;
      this.storageId = (new Date()).toString()
      this.totalSlices = Math.ceil(this.size/this.sliceSize)
      this.start = 0;
      this.fileExt = file.name.split('.')[ file.name.split('.').length - 1 ]
      return callback()
    } else {
      this.minifyFile( file , ( file ) => {
        this.file = file 
        this.size = file.size;
        this.fileType = 'image/png'
        this.storageId = (new Date()).toString()
        this.totalSlices = Math.ceil(this.size/this.sliceSize)
        this.start = 0;
        this.fileExt = 'png'
        return callback()
      })
    }
  }


  minifyFile( img , callback ) {
    var reader = new FileReader();
    reader.onload = (readerEvent : any ) => {
      var image = new Image();
      image.onload = (imageEvent : any ) => {
        // Resize the image
        var canvas = document.createElement('canvas'),
          max_size = 544,// TODO : pull max size from a site config
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        canvas.toBlob(( blob )=> {
          return callback(blob)
        });
      }
      image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(img);
  }

  setFilesToUploadArray( files ) {
    for ( var i = 0 ; i < files.length ; i++ ) {
      let fileStatus = {
        fileName : files[i].name ,
        fileStatus : 'toUpload'
      }
      this.fileStatuses.push( fileStatus )
    }
  }

  removeFileFromQueue( fileName : string ) {
    this.files = this.files.filter( ( file : any ) => {
      return ( file.name !== fileName )
    })
    this.fileStatuses = this.fileStatuses.filter( ( fileStatus : any )=> {
      return ( fileStatus.fileName !== fileName )
    })
  }

  startUpload() {
    this.cancelled = false 
    this.uploading = false 
    this.processing = false
    this.uploading = true
    this.signInToFirebase( () => {
      this.uploadNextChunk()
    })
  }

  signInToFirebase( callback : any ) {

    let URI = (this.competitionEntryOrGroupPicture === 'competitionEntry' ) ? 
      'contestEntry/getTokenToUploadEntry/' + this.objectId 
      :
      'groupPicture/getTokenToUploadPicture/' + this.objectId
    return this.dataService.getObject(URI).subscribe( ( response : any ) => {

      this.firebaseAuth.signInWithCustomToken( response.token )
        .then(( firebaseResponse ) =>{
          this.userId = firebaseResponse.user.uid
          this.accessToken = response.token
          callback()
        })
        .catch( (err) => {
          console.log( err )
        })

    })
  }

  uploadNextFile() {
    this.uploading = true
    this.setFileProps( this.files[this.currentFile] , () => {
      this.currentFile ++ 
      this.fileIndex ++

      this.uploadNextChunk()
    })
  }

  uploadNextChunk() {
    if ( this.cancelled === true )
      return
    this.end = this.start + this.sliceSize;
    
    if (this.size - this.end < 0) {
      this.end = this.size;
    }

    this.fileChunk = this.slice(this.file, this.start, this.end);

    this.uploadFile();
  }

  slice(file, start, end) {
    var slice = file.mozSlice ? file.mozSlice :
      file.webkitSlice ? file.webkitSlice :
      file.slice ? file.slice : this.noop;
  
    return slice.bind(file)(start, end);
  }

  noop() {}

  async uploadFile( )  {

    this.fileStatuses[ this.fileIndex ] = { fileName : this.fileName , fileStatus : 'uploading' }

    if ( this.cancelled === true )
      return
    console.log(this.fileType)
    let fileMetaData = {
      contentType : this.fileType
    }
    this.uploadPath = 'temp/' + this.userId + '/' + this.accessToken  + '/' + this.currentFile + '/' + this.fileName 
    this.filePath = this.uploadPath + '/' + this.currentslice + '.' + this.fileExt
    let storageRef = this.storage.ref( this.uploadPath )
    let childRef = storageRef.child( this.currentslice + '.' + this.fileExt )
    let uploadTask = childRef.put( this.fileChunk , fileMetaData )
    uploadTask.then(() => {
      this.percentLeft = Math.floor(this.currentslice/this.totalSlices)
      if ( this.currentslice === this.totalSlices ) {
        this.getSingleFile(  )
      }
      if (this.end < this.size && this.currentslice ) {
        if (this.currentslice < this.totalSlices)
          this.currentslice ++
        this.start += this.sliceSize;
        this.uploadNextChunk()
      }        

    })
    uploadTask.percentageChanges().subscribe(
      (value: number) => { 
        console.log( this.currentslice , this.totalSlices , value , value/this.totalSlices)
        this.percentLeft = (this.currentslice - 1)/this.totalSlices  + (value / 100) /this.totalSlices 
      }
    );
    console.log( this.percentChanges )
    // this.percentChanges = this.currentslice/this.totalSlices  + (uploadTask.percentageChanges() / this.totalSlices )
    // .on( 'state_changed' , ( snapshot ) => {
    //   if ( this.currentslice !== this.totalSlices )
    //     
    // } , ( err ) => {
    //   console.log( err )
    // } , ( ) => {

    // }) 
  }

  getSingleFile() {
    console.log( this.uploadPath , this.fileName , this.fileIndex )
    this.fileStatuses[ this.fileIndex ] = {fileName : this.fileName , fileStatus : 'processing'}
    this.processing = true
    this.uploading = false
    let URI = (this.competitionEntryOrGroupPicture === 'competitionEntry' ) ? 
      'contestEntry/' + this.objectId
      :
      'groupPicture/' + this.objectId
    let finishedUploadingBody = { 
      filePath :  this.uploadPath , 
      fileName : this.fileName , 
      fileIndex : this.fileIndex
    } 
    return this.dataService.postObject( URI , finishedUploadingBody ).subscribe( ( response : any ) => {
      console.log( response ) 
      this.processing = false
      if ( response.error ) 
        return this.fileStatuses[ this.fileIndex ] = {fileName : this.fileName , fileStatus : 'error'}
      else 
        this.fileStatuses[ this.fileIndex ] = {fileName : this.fileName , fileStatus : 'uploaded'}
      if ( this.currentFile < this.files.length )
        this.uploadNextFile() 
      else {
        if (this.competitionEntryOrGroupPicture === 'competitionEntry' )
          this.uploadFinished.emit( response.contestEntry )
        else 
          this.uploadFinished.emit( response.fileUri )
        this.uploadsComplete = true
      }
    })
  }

  cancelUpload() {
    this.cancelled = true 
    this.uploading = false 
    this.processing = false
    this.currentFile = 1
    this.currentslice = 1
    this.resetUploadStatus()
    this.percentLeft = 0
    var deletePath = this.uploadPath.split('/').slice(1 , 2 )
    [deletePath] = deletePath
    let storageRef = this.storage.ref( this.uploadPath )
    let fileRef = storageRef.child( deletePath )
    fileRef.delete().then(function() {
      console.log( 'deleted files' )
    }).catch(function(error) {
      console.log( error )
    });
  }

  resetUploadStatus() {
    for ( var i = 0 ; i < this.fileStatuses.length ; i++ ) {
      this.fileStatuses[i].fileStatus = 'toUpload'
    }
  }

}