import { Component , Input , OnInit , ElementRef , ViewChild , AfterContentInit } from '@angular/core'
import { Router } from '@angular/router'

import { Howler , Howl } from 'howler'

import { DataService , SessionService , UserDataService } from '../core/index'

import { IContest , IFan , IGroup , IContestEntry, ICurrency } from '../shared/interfaces'

import { IScoreCard } from './scoreCard/scoreCard.interface'

import { GroupService } from '../group/index'

import { InternationalService } from '../shared/international.service'

import * as WaveSurfer from 'wavesurfer'

import { DateService } from '../shared/date.service'

import { ContestService } from './contest.service'

import moment from 'moment' 
import 'moment-timezone'

const  ordinal = require('ordinal')

@Component({
  selector: 'contest', 
	templateUrl: "./contest.html" , 
  styleUrls : ["./contest.css"]
})

export class ContestComponent {

  noGroup : boolean
  noAllEntriesLink : boolean
  noEntryAttachment : boolean
  updatable : boolean
  noEntries : boolean
  alreadyEnteredContest : boolean

  showDetails : boolean = true
  showTimeline : boolean = false

  @ViewChild("audioContainer") audioContainerElement : ElementRef

  audioSound : any 

  songFile : any
  
  myFan : IFan
  @Input() group : IGroup
  contestEnded : boolean
  isGroupAdmin : boolean
  isJudge : boolean


  fileUploadContest : boolean = false
  textUploadContest : boolean = false
  preRegistrationEnded : boolean = false 

  pictureSubmissionType : boolean = false
  videoSubmissionType : boolean = false
  audioSubmissionType : boolean = false
  textSubmissionType : boolean = false
  showOtherRewards : boolean = false
  contestIsFree : boolean
  winnerSelected : boolean
  displayPotSize : number   
  rewardCurrencySymbol : string = '$'
  entryCurrencySymbol : string = '$'
  kindOfFan : any

  convertedPricePerVote : number 


  currencyWasSet : boolean 
  noRewards : boolean
  contestStarted : boolean
  votingEnded :  boolean
  votingTypeText : string 

  audioMuted : boolean 
  audioPaused : boolean = false

  displayStartTime : string | Date 
  displayVotingEndTime : string | Date 
  displayEarlyRegistrationTime : string | Date
  entryPrice : number | string

  reportedUser : any = {}
  ordinal : any

  @Input() viewType: string
  @Input() showEnterContestButton : boolean
	@Input() contest : IContest
  @Input() session : any

  scoreCard : IScoreCard | {} = {}

	constructor(
    private router : Router , 
    private dataService : DataService , 
    private dateService : DateService , 
    private groupService : GroupService , 
    private sessionService : SessionService , 
    private internationalService : InternationalService , 
    private userDataService : UserDataService,
    private contestService : ContestService
  ) {
    this.ordinal = ordinal
  }

  ngOnInit() {
    this.setViewType(this.viewType)
    this.setReportUserValues()
    this.getMyContestEntry()
    this.getMyFan(this.contest.groupUrl)
    this.getGroup(this.contest.groupUrl)
    this.noRewards = this.contestService.checkIfThereAreNoRewards(this.contest)
    this.getScoreCard()
    let tempAdmins : [{ username : string , profilePicture : string }] | any = []
    this.contest.admins.forEach( ( admin ) => {
      if ( tempAdmins.indexOf( admin ) === -1 )
        tempAdmins.push( admin )
    })
    this.contest.admins = tempAdmins
    this.sessionService.session.subscribe((session:any) => {
      this.session = session
      //this.setCurrency()
      var currency : ICurrency = {
        currencyWasSet: this.currencyWasSet,
        entryCurrencySymbol: this.entryCurrencySymbol,
        session: this.session,
        contest: this.contest,
        rewardCurrencySymbol: this.rewardCurrencySymbol,
        displayPotSize: this.displayPotSize
      }

      var newCurrency = this.contestService.setCurrency(currency)
      this.currencyWasSet = newCurrency.currencyWasSet
      this.entryCurrencySymbol = newCurrency.entryCurrencySymbol
      this.session = newCurrency.session
      this.contest = newCurrency.contest
      this.rewardCurrencySymbol = newCurrency.rewardCurrencySymbol
      this.displayPotSize = newCurrency.displayPotSize
      this.setPricePerVote()
    })

    if (this.contest.mediaType === 'audio' ) {
      this.audioSound = new Howl({
        buffer : true, 
        html5 : true ,
        src: [this.contest.media]
      });
    }

    this.preRegistrationEnded = this.contest.earlyRegistration && ( this.contest.earlyRegistration.registrationTotal >= this.contest.earlyRegistration.registrationMax || moment().isAfter( this.contest.earlyRegistration.registrationTime ))    
    this.contestEnded = moment().isAfter((new Date(this.contest.endOfContest)).getTime())
    this.winnerSelected = moment().isAfter((new Date(this.contest.winnerSelectedDate)).getTime())

    if ( this.contest.earlyRegistration )
      this.displayEarlyRegistrationTime = moment(this.contest.earlyRegistration.registrationTime).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
    if ( this.contest.startTime ) {
      this.contestStarted = moment().isAfter( this.contest.startTime )
      this.displayStartTime = moment(this.contest.startTime).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
    }
    if ( this.contest.votingEndTime ) {
      this.votingEnded = moment().isAfter( this.contest.votingEndTime )
      this.displayVotingEndTime = moment(this.contest.votingEndTime).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")      
    }
    this.contest.formattedEndOfContest =  moment(this.contest.endOfContest).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
    this.contest.formattedWinnerSelectedDate =  moment(this.contest.winnerSelectedDate).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
  }

  ngAfterContentInit() {
    if ( this.contest.mediaType === 'audio' ) {
      if ( window.innerWidth <= 767 ) {
        this.songFile = new Howl({
          src: [ this.contest.media ]
        });
      } else {
        let audioElement = document.createElement('div')
        audioElement.id = 'waveform' + this.contest._id
        this.audioContainerElement.nativeElement.append( audioElement )

        var wavesurfer = WaveSurfer.create({
          container: '#waveform' + this.contest._id ,
          waveColor: 'violet',
          progressColor: 'purple'
        });

        wavesurfer.on('ready', () => {
          this.songFile = wavesurfer
        });

        wavesurfer.load(this.contest.media);
      }
    }

  }


  setPricePerVote() {
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contest.currency )
      this.convertedPricePerVote = (this.contest.pricePerVote * this.session.exchangeRate / this.contest.exchangeRate)
    else 
      this.convertedPricePerVote = this.contest.pricePerVote
  }



  linkToDownload() {
    var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
    if (isIOS)
      window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
    else 
      window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
  }
  
  showAllRewardsModal() {    
    document.getElementById('contestRewardsModal').style.display = "block"
    document.getElementById('contestRewardsModalContent').style.display = "block"
  }

  hideAllRewardsModal() {    
    document.getElementById('contestRewardsModal').style.display = "none"
    document.getElementById('contestRewardsModalContent').style.display = "none"
  }

  becameAFan( newFan : IFan ) {
    this.myFan = newFan
  }

  getScoreCard() {
    let URI = 'scoreCard/getScoreCardByContestId/' + this.contest._id
    this.dataService.getObject( URI )
      .subscribe( ( response : any ) => {
        console.log('scorecard response' , response)
        if ( response.error )
          return console.log( 'getScoreCard Error:' , response.error )
        switch (response.scoreCard.votingCriteria.votingType) {
          case "votesToScore":
            this.votingTypeText = 'Votes count for ' + response.scoreCard.votingCriteria.scorePerVote + ' point' + (response.scoreCard.votingCriteria.scorePerVote === 1 ? '' : 's' ) + '.'
            break;
         case "tieBreaker" : 
           this.votingTypeText = 'Votes will be used for a tie breaker.' 
           break;
         case "crowdChoice" : 
           this.votingTypeText = 'Votes will be used for the crowd choice award.' 
           break;
          default:
            // code...
            break;
        }
        return this.scoreCard = response.scoreCard
      })
  }

  setViewType( viewType : string ) {
    this.showEnterContestForm()
    if (!this.contest.chosenEntry && !this.contest.ended) {
      this.noEntries = true
    }
    this.contest.formattedLength = Math.floor(this.contest.length/60) + 'm ' + this.contest.length%60 + 's'
    this.contest.formattedEndOfContest = this.dateService.stringifyDate(this.contest.endOfContest)
    this.setSubmissionViewType()
    switch (viewType) {
      case "noGroup" : {
        this.noGroup = true
        break 
      }
      case "contestEntries" : {
        this.noGroup = true
        this.noAllEntriesLink = true
        this.noEntryAttachment = true
        break
      }
    }
  }

  playAudio() {
    this.songFile.play()
    this.audioPaused = false
  }

  pauseAudio() {
    this.songFile.pause()
    this.audioPaused = true
  }

  toggleMute() {
    this.audioMuted = !this.audioMuted
    if ( window.innerWidth <= 767 ) 
      this.songFile.mute( this.audioMuted )
    else 
      this.songFile.setMute( this.audioMuted )
  }

  getMyFan( url : string ) {
    this.userDataService.userData
      .subscribe( ( userData : any ) => {
        console.log(userData)
        if (userData.myFans !== null)
          this.myFan = userData.myFans.filter( ( fan : IFan ) => {return (fan.groupUrl === url && fan.kindOfFan === this.contest.kindOfFan)})[0]
        console.log(this.myFan)
      })
  }

  getGroup( groupUrl : string ) {
    let URI = 'group/url/' + groupUrl
      this.dataService.getObject(URI)
          .subscribe((response) => {
            this.group = response.group;
            this.sessionService.session.subscribe( (session:any) => {
                console.log( this.contest.admins )
                let groupAdmins = [] , judges = []
                for ( var i = 0 ; i < this.contest.groupAdmins.length ; i++ ) {
                  groupAdmins.push( this.contest.groupAdmins[i].username )
                }
                for ( var i = 0 ; i < this.contest.admins.length ; i++ ) {
                  judges.push( this.contest.admins[i].username )
                }
                this.isJudge = ( judges.indexOf( session.username ) > -1 )
                this.isGroupAdmin = ( groupAdmins.indexOf ( session.username ) > -1 )
              if (this.contest.kindOfFan && this.group ) {
                if (this.contest.kindOfFan !== 'fan'){
                  for (var i = 0 ; i < this.group.kindsOfFans.length ; i++) {
                    if (this.group.kindsOfFans[i].fanTitle === this.contest.kindOfFan)
                      this.kindOfFan = this.group.kindsOfFans[i]
                  }
                } else {
                  this.kindOfFan = {
                    title: 'fan', 
                    priceToBecomeKindOfFan: this.group.priceToBecomeFan
                  }
                }
              }
            })
          },
        (err: any) => console.log(err),
        () => console.log("group"));
  }

  getMyContestEntry() {
    this.userDataService.userData
      .subscribe( ( userData : any) => {
        if (!userData || !userData.myContestEntries)
          return
        let myContestEntry = userData.myContestEntries.filter((ce : IContestEntry)=> {
          return (ce.contestId === this.contest._id)
        })[0]
        if ( this.contest.maxSubmissionsPerEntry > 1 )
          this.alreadyEnteredContest = (myContestEntry && myContestEntry.entryAttachments.length === this.contest.maxSubmissionsPerEntry ) ? true : false
        else 
          this.alreadyEnteredContest = ( myContestEntry && myContestEntry.entryAttachment ) ? true : false
      })
  }

  setReportUserValues() {
    this.reportedUser.userId = this.contest.creatorId
    this.reportedUser.objectId = this.contest._id
    this.reportedUser.objectType = 'contest'
  }

  showEnterContestForm() {
    if(
      this.contest.contestType === 'picture' || 
      this.contest.contestType === 'video' || 
      this.contest.contestType === 'pdf' ||
      this.contest.contestType === 'audio' 
    ) {
      this.fileUploadContest = true
    } else if (this.contest.contestType === 'text' ) {
      this.textUploadContest = true
    }
  }

  setSubmissionViewType() {
      switch (this.contest.contestType) {
        case "picture" : {
          this.pictureSubmissionType = true
          break 
        }
        case "audio" : {
          this.audioSubmissionType = true
          break 
        }
        case "video" : {
          this.videoSubmissionType = true
          break
        }
        case "text" : {
          this.textSubmissionType = true
          break
        }
      }
  }

  toggleOtherRewards() {
    this.showAllRewardsModal()
    this.showDetails = false 
    this.showTimeline = false
  }

  toggleDetails() {
    this.showDetails = !this.showDetails
    this.showTimeline = false 
    this.showOtherRewards = false
  }

  toggleTimeline() {
    this.showTimeline = !this.showTimeline
    this.showDetails = false 
    this.showOtherRewards = false
  }


  openInviteModal() {
      document.getElementById('inviteModal').style.display = "block"
      document.getElementById('inviteModalContent').style.display = "block"
  }

  closeInviteModal() {
      document.getElementById('inviteModal').style.display = "none"
      document.getElementById('inviteModalContent').style.display = "none"
  }
}