<div style="
  background-color: #251133;
  color: white;
  padding: 20px;
  border-radius: 5px;
  min-height: 400px;
  text-align: center;
">
  <h1>Upload to Spotidol</h1>
  <div 
    *ngIf="!uploadsComplete"
    style="
      margin: 10px auto;
    "
  >
    <p *ngIf="err">{{ err }}</p>
    <div *ngIf="!uploading && !processing && !uploaded && loadedContent">
      <label for="fileSelector" style="
        margin: auto;
        border-color: #6D507F;
        border-style: solid;
        border-width: 1px;
        color: white;
        background-color: rgb( 56 , 25 , 76 );
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;"
      >
        Select Files    
      </label>
      <input 
        type="file" 
        id="fileSelector"
        #fileSelector 
        multiple 
        (change)="onFileChange($event)" 
        style="
          display: none;
        " 
      >
        <button 
          *ngIf=" files.length > 0 "  
          (click)="startUpload()"
          style="
            margin: auto;
            border-color: #6D507F;
            border-style: solid;
            border-width: 1px;
            color: white;
            background-color: rgb( 56 , 25 , 76 );
            padding: 10px 16px;
            font-size: 18px;
            line-height: 1.3333333;
            border-radius: 6px;
            margin-left: 60px;
          " 
        >Upload</button>
    </div>
    <button 
      *ngIf="uploading || processing" 
      (click)="cancelUpload()"
      style="
        margin: auto;
        border-color: #6D507F;
        border-style: solid;
        border-width: 1px;
        color: white;
        background-color: rgb( 56 , 25 , 76 );
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
      " 
    >Cancel Upload</button>
    <div *ngIf="( uploading || processing ) && !cancelled " style="margin-top: 10px;">
      <p><span *ngIf="files.length > 1">{{ currentFile }} of {{ files.length }}</span> {{ percentLeft * 100 }}% Uploaded</p>
      <div
        style="
          width: 300px; 
          height: 10px; 
          border: 1px solid rgb( 147 , 127 , 147 ); 
          border-radius: 7px;
          margin: 0 auto;
        ">
        <div 
          [ngStyle]="{ 'width' : percentLeft * 296 + 'px' }" 
          style="
            background-color : green ; 
            height: 6px; 
            margin: 2px;
            border-radius: 7px;
          "><p style="font-size: 0px;">a</p></div>
      </div>
    </div>
    <h3 
      *ngIf="cancelled"
      style="
        margin-top: 10px;
      "
    >Upload Cancelled</h3>
    <h3 
      *ngIf="uploadsComplete"
      style="
        margin-top: 10px;
      "
    >Uploading Complete!</h3>
  </div>
  <div 
    *ngIf=" fileStatuses.length > 0"
    style="margin-top: 10px;" 
  >
  	<p *ngFor="let fileStatus of fileStatuses">
      <span>{{ fileStatus.fileName }}</span>
      <span style="padding-left: 7px;">
        <span *ngIf="fileStatus.fileStatus === 'toUpload'">Queued</span>
        <span *ngIf="fileStatus.fileStatus === 'uploading'">Uploading</span>
        <span *ngIf="fileStatus.fileStatus === 'processing'">Processing, Please Wait</span>
        <span *ngIf="fileStatus.fileStatus === 'uploaded'">Uploaded</span>
        <span *ngIf="fileStatus.fileStatus === 'error'">Error</span>
      </span>
      <a 
        (click)="removeFileFromQueue(fileStatus.fileName)"
        *ngIf="fileStatus.fileStatus  === 'toUpload'"
         style="padding-left: 7px;"
      >
        <img src="https://storage.googleapis.com/spotidol/cancel-btn.png" style="width: 12px; height: 12px;">
      </a>
    </p>
  </div>
  <h3 *ngIf="files.length === 0">No Files Selected</h3>
</div>

