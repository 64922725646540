<div 
	style="
		margin: 0px; 
		width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    top: -3px;
    margin-top : 10px;
    border-radius: 6px;
  "
>
	<div class="col-xs-12 purple-gray-background" style="height: 40px; top : 2px;">
		<span style=" color: white ; font-size: 16px; position: relative; top : 4px;">
			<a style="color: white;" routerLink="/profile/{{ contestEntry.contestantUsername }}">
				<span class="col-xs-offset-1">		
					<span *ngIf="contest.winnerSelected && (contest.scorePrivacy != 'hidden' || isGroupAdmin || isJudge || isMyEntry )">
						{{ contestEntry.position }}<span *ngIf="contestEntry.position === 1">st</span><span *ngIf="contestEntry.position === 2">nd</span><span *ngIf="contestEntry.position === 3">rd</span><span *ngIf="contestEntry.position > 3">th</span> - <span *ngIf="!!contestEntry.score">{{ contestEntry.score }} - </span>
					</span>
					<img src="{{ contestEntryProfilePicture }}" style="width:32px;height:32px;border-radius: 16px; display: inline;">
					<span *ngIf="contestEntry.contestantUsername !== contest.creatorUsername">
						{{ contestEntry.contestantUsername }}
					</span>
				</span>
			</a>
			<span> - </span>
			<span *ngIf="!contest.winnerSelected && ( isGroupAdmin || isJudge )">
				<score-entry [scoreCard]="scoreCard" [contestEntry]="contestEntry" [contest]="contest"></score-entry>
			</span>
		</span>
		<div style="position: absolute; top: 8px; right: 10px;">
			<a style="display: inline-block;" *ngIf="isGroupAdmin || isJudge" href="{{ contestEntry.entryAttachment }}">
				<img 
					src="https://storage.googleapis.com/spotidol/mediaDownload.png" 
					style="width: 18px; height: 18px;" 
				/>
			</a>
			<media-menu [reportedUserDetails]="contestEntry.reportedUserDetails" [mediaObject]="contestEntry" [mediaObjectType]="'contestEntry'" [whiteOrBlack]="'white'" [showShareButtons]="contestEntry.entryPrivacy != 'private' " ></media-menu>
		</div>
	</div>
	<div style="margin-bottom: 0px;" [hidden]=" !contestEntry.entryAttachment">	
		<div *ngIf="contest.contestType == 'pdf'" style="margin-top : 40px;">
			<pdf-reader [pdfUrl]="contestEntry.entryAttachment"></pdf-reader>
		</div>
		<div [hidden]="!pictureSubmissionType" class="col-xs-12 no-margins no-padding" style="margin-bottom: 0px; overflow: hidden; background-color: black;
    width: 100%;
    height: 600px;"  >	
			<div 
				style="
					width: 100% ; 
					height: 100% ; 
			    background-repeat: no-repeat;
			    background-size: contain;
			    background-position: center center;
			    padding: 0px;
			    margin: auto;
				" 
				class="col-xs-12"
				[ngStyle]="{ 'background-image' : pictureSubmission }"
			>
				<p style="margin-bottom: 0px; font-size: 0px;">a</p>
			</div>
			<!-- <div #picture class="pic-container block no-margins no-padding col-sm-offset-1 col-xs-offset-0 hide-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px; overflow: hidden;">
				<p style="margin-bottom: 0px; font-size: 0px;">a</p>
			</div>
			<div #picture class="pic-container block no-margins no-padding show-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px; overflow: hidden;">
				<p style="margin-bottom: 0px; font-size: 0px;">a</p>
			</div> -->
		</div>
		<div 
			[hidden]="contestEntry.contestType != 'pictureGallery'" class="col-xs-12 no-margins no-padding" style="
				margin-bottom: 0px; 
				overflow: hidden; 
				background-color: black;
		    width: 100%;
		    height: 600px;
		  "
	  >
			<a routerLink='/competition/{{ contestEntry.contestId }}/pictureGallery/{{ contestEntry.contestantUsername }}' style="margin-bottom: 0px; 
				background-color: black;
		    width: 100%;
		    height: 600px;"
	    >
					<div 
						style="
							width: 100% ; 
							height: 100% ; 
					    background-repeat: no-repeat;
					    background-size: contain;
					    background-position: center center;
					    padding: 0px;
					    margin: auto;
						" 
						class="col-xs-12"
						[ngStyle]="{ 'background-image' : pictureSubmission }"
					>
						<p style="margin-bottom: 0px; font-size: 0px;">a</p>
					</div>
				<!-- <div class="pic-container block no-margins no-padding col-sm-offset-1 col-xs-offset-0 hide-on-phones" style=" overflow: hidden;" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}">
					<p style="margin-bottom: 0px; font-size: 0px;">a</p>
				</div>
				<div #pictureGallery class="pic-container block no-margins no-padding show-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px; overflow: hidden;">
					<p style="margin-bottom: 0px; font-size: 0px;">a</p>
				</div> -->
				<div [ngStyle]="{'width' : pictureWidth , 'height' : 60 , 'position' : 'absolute' , 'bottom' : '60px' , backgroundColor : 'rgba(0 ,0 ,0 , 0.3)'}">
					<h3 style="color: white;">+{{contestEntry.entryAttachments.length - 1}} more...</h3>
				</div>
			</a>
		</div>
		<div class="col-xs-12 no-margins no-padding">
			<div class="pic-container" *ngIf = "videoSubmissionType" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="background-color: black;">
				<video
			    id="my-player"
			    class="video-js"
					[ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}"
			    controls
			    preload="auto"
			    style="background-color: black; width : 100% ; height: 70vh;"
          data-setup='{ "techOrder": ["java"] }'>
				>
				  <source src="{{ contestEntry?.entryAttachment }}"/>
				  <p class="vjs-no-js">
				    To view this video please enable JavaScript, and consider upgrading to a
				    web browser that
				    <a href="http://videojs.com/html5-vdieo-support/" target="_blank">
				      supports HTML5 video
				    </a>
				  </p>
				</video>
			</div>
		</div>
		
		<div [hidden]= "!audioSubmissionType" class="col-xs-12 no-margins no-padding">	
			<div style="background: black;" id="audioCanvasContainer" #audioCanvasContainer>
				<canvas id="audioCanvas" #audioCanvas style="
			    width: 100%;
			    height: 130px;
		    "></canvas>
			</div>	
			<div style="background: black;" id="audioContainer" #audioContainer></div>	
			<a>
				<div *ngIf="songLoaded" style="height : 30px ; background-color : black; margin : 0 auto; z-index: 2; bottom : 10px; position: absolute;" class="col-xs-12">
					<div style="float: none ; position: relative ; background-color: black; " class="col-sm-offset-6 col-xs-offset-4">
						<a *ngIf="!audioPaused" (click)="pauseAudio()">
							<img src="https://storage.googleapis.com/spotidol/pause.png" style="width: 25px; height: 25px; margin : 2px ;" />
						</a>
						<a *ngIf="audioPaused" (click)="playAudio()">
							<img src="https://storage.googleapis.com/spotidol/play.png" style="width: 25px; height: 25px; margin : 2px ;" />
						</a>
						<span *ngIf="contestEntry.totalViews > 0" style="color: white;">{{ contestEntry.totalViews}} View<span *ngIf="contestEntry.totalViews > 1">s</span></span>
					</div>
				</div>
			</a>
		</div>

		<div *ngIf = "textSubmissionType" class="col-xs-12 no-margins no-padding" style="background-color: white;">
			<div class="col-xs-offset-1 col-xs-10 contestEntrySection roundBorders "><p [ngStyle]="{'font-size': textSubmissionFontSize }">{{ contestEntry.entryAttachment }}</p>
			</div>
		</div>

		<div *ngIf = "contestEntry.contestType === 'code'" class="col-xs-12 no-margins no-padding" style="background-color: white;">
			<div class="col-xs-offset-1 col-xs-10 contestEntrySection roundBorders ">
				<a href="https://github.com/{{ contestEntry.entryAttachment }}">
					<div style="margin: 5px;">
						<img 
							src="https://image.flaticon.com/icons/svg/25/25231.svg" 
							style="
								height: 24px; 
								width : 24px;
							    top: -5px;
							    position: relative;
							"
						>
						<span style="font-size : 24px; color: black;">View on Github</span>
					</div>
				</a>
			</div>
		</div>
		
	</div>

	<div style="z-index: 2;" *ngIf="contestEntry.entryAttachment !== null && contestEntry.entryPrivacy !== 'private' ">
		<contest-vote *ngIf="scoreCard && !scoreCard.votingCriteria.votersGetJudgingCriteria" [contestEntry]="contestEntry"></contest-vote>
		<score-card-contest-vote *ngIf="scoreCard && scoreCard.votingCriteria && scoreCard.votingCriteria.votersGetJudgingCriteria" [contestEntry]="contestEntry" [scoreCard]="scoreCard"></score-card-contest-vote>
	</div>
</div>
<div *ngIf="contest.winnerSelected || isGroupAdmin || isJudge || isCommenter || isMyEntry">
	<contest-entry-comments [contestEntry]="contestEntry"></contest-entry-comments>
</div>