import { Component , OnInit , Input , ChangeDetectorRef , NgZone } from '@angular/core'
import { ActivatedRoute , Router , NavigationEnd } from '@angular/router'

import { SessionService , DataService , UserDataService } from '../../core/index'
import { InternationalService } from '../../shared/international.service'
import { IContestEntry , IGroup , IContest , IFan } from '../../shared/interfaces'
import { IScoreCard } from '../../contests/scoreCard/scoreCard.interface'


import moment from 'moment' 
import 'moment-timezone'

@Component({
	selector: 'contest-entries', 
	templateUrl: "./contestEntries.html", 
	styleUrls : ["./contestEntries.css"]
})

export class ContestEntriesComponent implements OnInit {

	group : IGroup
	relatedGroups : IGroup[]
	fan : IFan
	contests : IContest[] = []
	contestEntries : IContestEntry[] = []
	displayedEntries : IContestEntry[] = []
	registrations : IContestEntry[] = []
	selectedContestEntry : IContestEntry
	myCompetitionEntry : IContestEntry
	scoreCard : IScoreCard
	userInstance : any
	err : string
	page : number = 1
	totalPages : number
	searchValue : string
	score : number
	//view variables
	submittedContestWinnerToServer : boolean  = false
	errorSubmittingContestWinnerToServer : boolean = false
	isGroupAdmin : boolean = false
	currencySymbol : string = '$'
	winnerSelected : boolean
	pictureSubmissionType : boolean = false
	videoSubmissionType : boolean = false
	audioSubmissionType : boolean = false
	textSubmissionType : boolean = false
	showEnterContestButton : boolean = true
	showScoreContestEntryButton : boolean = false
	noFoundContestEntries : boolean = false
	fanLoaded : boolean = false
	session : any 
	countdownText : string
	countdownStart : string | Date
	loadedContestEntries : boolean = false
	judgeInviteUrl : string 
	commenterInviteUrl : string 
	viewerInviteUrl : string 
	showInviteModal : boolean

  isJudge : boolean
  isCommenter : boolean
  isViewer : boolean

	constructor(
		private changeDetector : ChangeDetectorRef, 
		private route : ActivatedRoute ,  
		private router : Router , 
		private zone : NgZone , 
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService , 
		private internationalService : InternationalService
	) {}

	ngOnInit () {
	    this.route.data
	      .subscribe(( data : any ) => {
	      	let contestEntriesData = data.contestEntriesData
	      	Object.keys(contestEntriesData).forEach( ( data ) => this[ data ] = contestEntriesData[ data ] )
	      	this.getMyFan()
			    this.setHeaderText()
			    this.totalPages = 1 
					if ( this.route.snapshot.params['usernameSearch'] )
						this.searchContestEntries( this.route.snapshot.params['usernameSearch'] )
					else 
						this.getContestEntries( 1 , this.route.snapshot.params['contestId'] )				
					let search 
					if ( this.group ) {	
						search = (this.group.tags && (this.group.tags.length > 0)) ? 
							this.group.tags[0] 
							:
							this.group.groupName
					} else
						search = ""

					this.searchGroups( search )
			    this.sessionService.session.subscribe(( session : any ) => {
		      	this.session = session

						this.judgeInviteUrl = this.route.snapshot.params['judgeInviteUrl']
						this.commenterInviteUrl = this.route.snapshot.params['commenterInviteUrl']
						this.viewerInviteUrl = this.route.snapshot.params['viewerInviteUrl']
						if ( 
							!this.session.loggedIn 
							&&
							( 
								this.judgeInviteUrl || 
								this.commenterInviteUrl || 
								this.viewerInviteUrl 
							)
						) 
							return this.showInviteModal = true
						this.isJudge = !!this.contests[0].admins.filter( ( admin ) => { 
							return ( admin.username === this.session.username )
						})[0]
						this.isGroupAdmin = !!this.contests[0].groupAdmins.filter( ( admin ) => { 
							return ( admin.username === this.session.username )
						})[0]
						this.isCommenter = !!this.contests[0].commenters.filter( ( commenters ) => { 
							return ( commenters.username === this.session.username )
						})[0]
						this.isViewer = !!this.contests[0].viewers.filter( ( viewer ) => { 
							return ( viewer.username === this.session.username )
						})[0]
						if ( this.judgeInviteUrl && !this.isJudge )
							this.addJudgeByInviteUrl()
						if ( this.commenterInviteUrl && !this.isCommenter )
							this.addCommenterByInviteUrl()
						if ( this.viewerInviteUrl && !this.isViewer )
							this.addViewerByInviteUrl()	
		      })
	    	})
	}

	addJudgeByInviteUrl() {
		let URI = 'contest/addJudgeByInviteUrl' , 
			addJudgeBody = {
				contestId : this.contests[0]._id ,
				judgeInviteUrl : this.judgeInviteUrl 
			}
		this.dataService.postObject( URI , addJudgeBody )
			.subscribe( ( response : any ) => {
				if ( response.error )
					return this.err = response.error
				this.isJudge = true
				let admin = {
					username : this.session.username
				}
				this.contests[0].admins.push( admin )
				this.getContestEntries( 1 , this.route.snapshot.params['contestId'] )
			})
	}
	
	addCommenterByInviteUrl() {
		let URI = 'contest/addCommenterByInviteUrl' , 
			addCommenterBody = {
				contestId : this.contests[0]._id ,
				commenterInviteUrl : this.commenterInviteUrl 
			}
		this.dataService.postObject( URI , addCommenterBody )
			.subscribe( ( response : any ) => {
				if ( response.error )
					return this.err = response.error
				this.isCommenter = true
				let commenter = {
					username : this.session.username
				}
				this.contests[0].commenters.push( commenter )
				this.getContestEntries( 1 , this.route.snapshot.params['contestId'] )
			})
	}

	addViewerByInviteUrl() {
		let URI = 'contest/addViewerByInviteUrl' , 
			addViewerBody = {
				contestId : this.contests[0]._id ,
				viewerInviteUrl : this.viewerInviteUrl 
			}
		this.dataService.postObject( URI , addViewerBody )
			.subscribe( ( response : any ) => {
				if ( response.error )
					return this.err = response.error
				this.isViewer = true
				let viewer = {
					username : this.session.username
				}
				this.contests[0].viewers.push( viewer )
				this.getContestEntries( 1 , this.route.snapshot.params['contestId'] )
			})
	}

	endCompetition() {
		let URI = 'contest/endContest/' + this.contests[0]._id
		this.dataService.postObject(URI, {})
			.subscribe( ( response : any ) => {
				if (response.error)
					console.log(response.error)
      	else {
      		this.contests[0].winnerSelected = true	
      		this.setHeaderText()
      		this.closeEndCompetitionModal()
      	}
			})		
	}

	searchGroups( search : string ) {
			let URI = 'group/search?groupName=' + search + '&page=1'
			this.dataService.getObject(URI)
				.subscribe( ( response : any ) => {
					if (response.error)
						console.log(response.error)
	      	else
	      		this.relatedGroups = response.groups
				})
	}

	setLoadedEntries() {
		let loadAmount = 2
		if ( window.innerHeight > 600 )
			loadAmount = 4
		if ( window.innerHeight > 1000 )
			loadAmount = 8
		if ( window.innerHeight > 1400 )
			loadAmount = 10
		this.displayedEntries.push(...this.contestEntries.slice( this.displayedEntries.length , this.displayedEntries.length + loadAmount ))
	}

	selectContestEntry( contestEntry : IContestEntry ) {
		this.selectedContestEntry = contestEntry
	}

	setHeaderText() {
	    let countdownStart
	    let countdownText : 'Starts' | 'Ends' | 'Voting Ends' | 'Winner Chosen' | 'Ended' | null = (this.contests[0].winnerSelected === true) ?
	    'Ended'
	    :
	    (this.contests[0].startTime && moment().isBefore( this.contests[0].startTime )) ?
	      'Starts'
	      :
	      (this.contests[0].endOfContest && moment().isBefore( this.contests[0].endOfContest )) ? 
	        'Ends' 
	        :
	        (this.contests[0].votingEndTime && moment().isBefore( this.contests[0].votingEndTime)) ? 
	          'Voting Ends' 
	          :
	          (this.contests[0].winnerSelectedDate && moment().isBefore( this.contests[0].winnerSelectedDate )) ?
	            'Winner Chosen' 
	            :
	            'Ended'
	    switch (countdownText) {
	      case "Starts":
	        countdownStart = this.contests[0].startTime
	        break;
	      case "Ends" :
	        countdownStart = this.contests[0].endOfContest
	        break;
	      case 'Voting Ends' :
	        countdownStart = this.contests[0].votingEndTime
	        break
	      case 'Winner Chosen' :
	        countdownStart = this.contests[0].winnerSelectedDate
	        break
	      case 'Ended' :
	      	countdownStart = null
	      default:
	        break;

	    }  
		this.countdownText = countdownText
		this.countdownStart = countdownStart
	}

	getGroup( groupUrl : string ) {
		let URI = 'group/url/' + groupUrl
    	this.dataService.getObject(URI)
        .subscribe((response) => {
          this.group = response.group;
			  	this.getContest(this.route.snapshot.params['contestId'])
        },
        (err: any) => console.log(err),
        () => console.log("group"));
	}

	getContest( contestId : string ) {
		let URI = 'contest/getContestById/' + contestId
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
				var endOfContest = response.contest.endOfContest
				var dateAndTime = endOfContest.split('T')
				var date = dateAndTime[0].split('-')
				var year = date[0]
				var month = date[1]
				var day = date[2]
				if (day.charAt(0) === '0')
					day = day.charAt(1)

				var stringifiedMonth = ''
				switch (month) {
					case '01': {
						stringifiedMonth = 'Jan'
						break
					}
					case '02': {
						stringifiedMonth = 'Feb'
						break
					}
					case '03': {
						stringifiedMonth = 'Mar'
						break
					}
					case '04': {
						stringifiedMonth = 'Apr'
						break
					}
					case '05': {
						stringifiedMonth = 'May'
						break
					}
					case '06': {
						stringifiedMonth = 'Jun'
						break
					}
					case '07': {
						stringifiedMonth = 'Jul'
						break
					}
					case '08': {
						stringifiedMonth = 'Aug'
						break
					}
					case '09': {
						stringifiedMonth = 'Sep'
						break
					}
					case '10': {
						stringifiedMonth = 'Oct'
						break
					}
					case '11': {
						stringifiedMonth = 'Nov'
						break
					}
					case '12': {
						stringifiedMonth = 'Dec'
						break
					}
				}
			    response.contest.formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
	          this.contests[0] = response.contest
			  this.setSubmissionTypeViewType(response.contest.contestType)
	        },
        (err: any) => console.log(err),
        () => console.log("contests"));
	}

	linkToDownload() {
		var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
		if (isIOS)
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
		else 
			window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
	}

	getContestEntries( page : number , contestId : string ) {
		if ( page > this.totalPages ) 
			return
		let URI = 'contestEntry/getContestEntriesByContestId/?page=' + page + '&contestId=' + contestId
    	this.dataService.getObject(URI)
        .subscribe((response : any) => {
        	for(var i = 0; i < response.contestEntries.length ; i++ ) {
				  	response.contestEntries[i].reportedUserDetails = {}
				  	response.contestEntries[i].reportedUserDetails.userId = response.contestEntries[i].contestantId
				  	response.contestEntries[i].reportedUserDetails.objectId = response.contestEntries[i]._id
				  	response.contestEntries[i].reportedUserDetails.objectType = 'contestEntry'
        	}
        	this.totalPages = response.pages
		      this.registrations.push(...response.contestEntries.filter(( ce : IContestEntry) => {
		      	return ( !ce.entryAttachment )
		      })) 
		      this.contestEntries.push(...response.contestEntries.filter(( ce: IContestEntry ) => {
		      	return ( ce.entryAttachment )
		      }))
		      this.loadedContestEntries = true
		      console.log('contestEntriesData' ,  response )
	      	this.orderContestEntries()
	      	this.setLoadedEntries()
      })
	}
	
	orderContestEntries() {
			if (this.contests[0].winnerSelected === false)
				return
			if (this.contestEntries && this.contestEntries.length > 0) {	
				this.contestEntries.sort( (a : any, b : any) => {
					return b.score - a.score
				})
				for (let i = 0 ; i < this.contestEntries.length ; i++ ) {
					this.contestEntries[i].position = i + 1
				}
			}
	}

	getMyFan( ) {
		this.sessionService.session.subscribe( (session:any) => {
			this.userInstance = session
    	if ((session === undefined) || (session.username === '') || (session.username === undefined)) 
			return 
		})
		this.userDataService.userData.subscribe( ( userData : any ) => {
			this.myCompetitionEntry = userData.myContestEntries.filter( ( ce : IContestEntry ) => { return ( ce.contestId === this.contests[0]._id )} )[0]
			this.changeDetector.markForCheck()
			if (!userData.myFans)
				return
			this.fan = userData.myFans.filter(( fan : IFan ) => { return ( fan.kindOfFan === this.contests[0].kindOfFan && fan.groupId === this.contests[0]._id)})
		})
	}

	searchContestEntries( username : string ) {
		let URI = 'contestEntry/getContestEntryByContestantUsername/?username=' + username + '&contestId=' + this.contests[0]._id
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
	        	if (response.contestEntry !== null) {
	        		this.noFoundContestEntries = false
		        	var contestEntries = [response.contestEntry]
		        	for(var i = 0; i < contestEntries.length ; i++ ) {
					  	contestEntries[i].reportedUserDetails = {}
					  	contestEntries[i].reportedUserDetails.userId = contestEntries[i].contestantId
					  	contestEntries[i].reportedUserDetails.objectId = contestEntries[i]._id
					  	contestEntries[i].reportedUserDetails.objectType = 'contestEntry'


						var created = contestEntries[i].created
						var dateAndTime = created.split('T')
						var time = dateAndTime[1].split(':')
						var date = dateAndTime[0].split('-')
						var month = date[1]
						var day = date[2]
						if (day.charAt(0) === '0')
							day = day.charAt(1)

						var hour = time[0]
						var minute = time[1]
						if (hour.charAt(0) === '0')
							hour = hour.charAt(1)
						var hourNumber = parseInt(hour)
						if (hourNumber > 12) {
							hourNumber = hourNumber - 12
							hour = hourNumber + ''
							var timeString = hour + ':' + minute + ' PM UT'
						} else {
							var timeString = hour + ':' + minute + ' AM UT'
						}
						var stringifiedMonth = ''
						switch (month) {
							case '01': {
								stringifiedMonth = 'Jan'
								break
							}
							case '02': {
								stringifiedMonth = 'Feb'
								break
							}
							case '03': {
								stringifiedMonth = 'Mar'
								break
							}
							case '04': {
								stringifiedMonth = 'Apr'
								break
							}
							case '05': {
								stringifiedMonth = 'May'
								break
							}
							case '06': {
								stringifiedMonth = 'Jun'
								break
							}
							case '07': {
								stringifiedMonth = 'Jul'
								break
							}
							case '08': {
								stringifiedMonth = 'Aug'
								break
							}
							case '09': {
								stringifiedMonth = 'Sep'
								break
							}
							case '10': {
								stringifiedMonth = 'Oct'
								break
							}
							case '11': {
								stringifiedMonth = 'Nov'
								break
							}
							case '12': {
								stringifiedMonth = 'Dec'
								break
							}
						}
					    contestEntries[i].formattedCreationDate = stringifiedMonth + ' ' + day + ' ' + timeString
		        	}
	          		this.contestEntries = contestEntries
	        	} else {
	        		this.contestEntries = null
	        		this.noFoundContestEntries = true
	        	}
	        },
        (err: any) => console.log(err),
        () => console.log("contestEntries"));
	}

	getNextContestEntriesPage() {
		this.page ++
		this.getContestEntries( this.page , this.route.snapshot.params['contestId'])
	}


	setViewType( contest : IContest ) {
		let contestType = contest.contestType
	}

	setSubmissionTypeViewType( type : string ) {
	    switch (type) {
	      case "picture" : {
	        this.pictureSubmissionType = true
	      	this.videoSubmissionType = false
	      	this.audioSubmissionType = false
	      	this.textSubmissionType = false
	        break 
	      }
	      case "video" : {
	      	this.videoSubmissionType = true
	        this.pictureSubmissionType = false
	      	this.audioSubmissionType = false
	      	this.textSubmissionType = false
	      	break
	      }
	      case "audio" : {
	      	this.audioSubmissionType = true
	        this.pictureSubmissionType = false
	      	this.videoSubmissionType = false
	      	this.textSubmissionType = false
	      	break
	      }
	      case "text" : {
	      	this.textSubmissionType = true
	        this.pictureSubmissionType = false
	      	this.videoSubmissionType = false
	      	this.audioSubmissionType = false
	      	break
	      }
	    }
	}

	setEnterContestButtonViewType( ended : boolean ) {
		if (ended === true) {
			return this.showEnterContestButton = false 
		} 
		return this.showEnterContestButton = true 
	}

  openEndCompetitionModal() {
    document.getElementById('endCompetitionModal').style.display = "block"
    document.getElementById('endCompetitionModalContent' ).style.display = "block"
  }

  closeEndCompetitionModal() {
    document.getElementById('endCompetitionModal' ).style.display = "none"
    document.getElementById('endCompetitionModalContent' ).style.display = "none"
  }


}