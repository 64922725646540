<div style="color : white ;">
	<h2 style="text-align: center;">Entry Details</h2>
	<div class="col-xs-12">
		<span>Entry Type </span>
		<select class="roundBorders dark-purple-background input-styling" [(ngModel)]="contestType" [(ngModel)]="createContestObject.contestType" id="contestType" type="text" placeholder="Please Select One">
			<option>Please Select One</option>
			<option value="picture">Picture</option>
			<option value="pictureGallery">Picture Gallery</option>
			<option value="video">Video</option>
			<option value="audio">Audio</option>
			<option value="pdf">Pdf</option>
			<option value="text">Text</option>
			<option value="code">Code</option>
		</select>
	<div [hidden]="contestType != 'pictureGallery'">
		<span>Max Pictures Per Entry</span>
		<input class="roundBorders dark-purple-background input-styling" *ngIf="contestType == 'pictureGallery'" [(ngModel)]="createContestObject.maxSubmissionsPerEntry" id="max # of pictures" type="text" mask="00" placeholder="max # of pictures">
	</div>
	</div>
	<div class="col-xs-12">
		<span>Entry Privacy </span>
		<select 
			class="roundBorders dark-purple-background input-styling" 
			[(ngModel)]="createContestObject.entryPrivacy" 
			id="entryPrivacy" 
			type="text" 
			placeholder="Please Select One"
			style="margin: auto ; margin-bottom: 10px;" 
		>
<option>Please Select One</option>
			<option value="private">Private (Judges Only)</option>
			<option value="public">Public (Anyone With Link)</option>
		</select>
	</div>

	<div class="col-xs-12">
		<span>Entry Price {{ currencySymbol }} </span>
		<input class="roundBorders dark-purple-background input-styling" [(ngModel)]="createContestObject.contestPrice" id="contestPrice" type="text"  mask="9*.99" placeholder="30, 5 , 10">	
	</div>
	<div class="col-xs-12" style="margin-bottom: 30px;">
		<span>
			Entry goes to pot (You keep 20%)
		  <input 
		 		style="
			    height: 20px;
			    top: 3px;
			    width: 20px;
			    position: relative;
		    "
		  	type="checkbox"
		  	[(ngModel)]="createContestObject.entryGoesToPot"
		  >
		</span>
	</div>
	<div class="col-xs-12">	
		<div class="col-xs-6">
			<p style="text-align: center;">Entry Ends</p>
			<div class="col-xs-12">
				<div style="display: flex;">
					<ngb-datepicker 
						(dateSelect)="setEndOfCompetitionDate($event)"
						class="lighter-background"
						style="
						    color: black;
						    margin: 0 auto;
						" 
						[minDate]="today"
					#d></ngb-datepicker>
				</div>
			</div>
			<div class="col-xs-12">
				<div 
					style="
					    margin: 10px auto;
					    color : black; 
					    display: flex;
					    justify-content: center;
					"
				>
					<ngb-timepicker [(ngModel)]="endOfContestTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
				</div>
			</div>
		</div>
		<div class="col-xs-6">
			<p style="text-align: center;">Date Winner is Announced</p>
			<div style="display: flex;">
				<ngb-datepicker
					(dateSelect)="setWinnerSelectedDate($event)"
					class="lighter-background"
					style="
					    color: black;
					    margin: 0 auto;
					" 
					[minDate]="today"
				#d></ngb-datepicker>
			</div>
			<div class="col-xs-12">
				<div
					style="
					    margin: 10px auto;
					    color : black; 
					    display: flex;
					    justify-content: center;
					"
				>
					<ngb-timepicker [(ngModel)]="winnerSelectedTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
				</div>
			</div>
		</div>
	</div>
	<input (click)="submitDetails()" class="btn btn-info btn-lg" type= "submit" value="Submit">
</div>