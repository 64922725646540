
<<!-- div id="uploadContestEntriesMobileModal" class="cssModal" role="dialog">
	<div id="uploadContestEntriesMobileModalContent" class="cssModalContent modal-content row" style="max-width: 700px;">
	  <div>
	    <button type="button" class="btn btn-default" (click)="closeDlAppModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
	    <upload-contest-entries-mobile-modal></upload-contest-entries-mobile-modal>
	  </div>
	</div>
</div> -->
<!-- <div id="uploadAudioEntriesModal" class="cssModal" role="dialog">
	<div id="uploadAudioEntriesModalContent" class="cssModalContent modal-content row" style="max-width: 700px;">
	  <div>
	    <button type="button" class="btn btn-default" (click)="closeUploadAudioEntriesModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
	    <h3 style="text-align: center;">You can only upload audio entries from a computer.</h3>
	  </div>
	</div>
</div> -->
<!-- <div id="uploadContestEntriesMobileModal" class="cssModal" role="dialog">
	<div id="uploadContestEntriesMobileModalContent" class="cssModalContent modal-content row" style="max-width: 700px;">
	  <div>
	    <button type="button" class="btn btn-default" (click)="closeDlAppModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
	    <upload-contest-entries-mobile-modal></upload-contest-entries-mobile-modal>
	  </div>
	</div>
</div> -->
<div 
style="
  overflow: hidden;
"

class="contest-entry-page-phone-background" 
>
	<div style="
    position: absolute;
    right: 0px;
    top: 25px;
    z-index: 5;
  ">
		<media-menu [reportedUserDetails]="reportedUser" [mediaObject]="contest" [mediaObjectType]="'contest'" [whiteOrBlack]="'white'"></media-menu>
	</div>
	<video
		*ngIf="contest.mediaType === 'video'"
	    id="my-player"
	    class="medium-content-height col-xs-10 col-xs-offset-1 video-js"
	    controls
	    preload="auto"
	    data-setup='{}'>
	  <source src="{{ contest?.media }}"/>
	  <p class="vjs-no-js">
	    To view this video please enable JavaScript, and consider upgrading to a
	    web browser that
	    <a href="http://videojs.com/html5-video-support/" target="_blank">
	      supports HTML5 video
	    </a>
	  </p>
	</video>
	<div [hidden] = "contest.mediaType !== 'audio'" class="col-xs-12 no-margins no-padding" style="top: -2px;">		
		<div style="background: black;" id="audioContainer" #audioContainer></div>		
		<div style="height : 70px ; background-color: black ; margin : 0 auto; z-index: 2; top : -2px;" class="col-xs-12">
			<div style="float: none ; position: relative ; margin : auto; width : 245px; background-color: black; ">
				<a (click)="toggleMute()">
					<img src="https://storage.googleapis.com/spotidol/mute.png" style="width: 40px; height: 40px; margin : 15px ;" />
				</a>
				<a *ngIf="!audioPaused" (click)="pauseAudio()">
					<img src="https://storage.googleapis.com/spotidol/pause.png" style="width: 40px; height: 40px; margin : 15px ;" />
				</a>
				<a *ngIf="audioPaused" (click)="playAudio()">
					<img src="https://storage.googleapis.com/spotidol/play.png" style="width: 40px; height: 40px; margin : 15px ;" />
				</a>
				<a href="{{ contest.media }}">
					<img 
						src="https://storage.googleapis.com/spotidol/mediaDownload.png" 
						style="width: 40px; height: 40px; margin : 15px ;" 
					/>
				</a>
			</div>
		</div>
	</div>
	<contest-video-upload *ngIf="isGroupAdmin || isJudge" [contest]="contest"></contest-video-upload>
	<div style="width: 100%; margin : 10px 0px ;" *ngIf="!contestEnded && !isGroupAdmin && !isJudge">
<!-- 		<div 
			class="show-on-phones" 
			style="width: 100%;"
		>		
			<div 
				style="
			    margin: 0 auto;
			    float: none;
			    display: block;
		    "
	    >	
	    	<p>
			    <button
			      (click)="openMobileModal()"
			      type="button" 
			      class="white-text btn btn-info btn-lg purple-gray-background" 
			      style="
			        margin: auto;
			        border-width: 0px;
			        display: block;
			      "
			    >
				    <a class="white-text">Enter</a>
				</button>
			</p>
			</div>
		</div> -->
		<div class="col-xs-12">		
			<div class="col-xs-offset-1" *ngIf="textUploadContest">
				<p>
					<upload-text-contest-entry [group]="group" [contest]="contest" [myFan]="myFan"></upload-text-contest-entry>
				</p>
			</div>
			<div *ngIf="fileUploadContest">
				<p>
					<upload-contest-entries [group]="group" [contest]="contest" [myFan]="myFan" ></upload-contest-entries>
				</p>
			</div>
			<div *ngIf="contest.contestType === 'code'">
				<p>
					<upload-code-contest-entry [group]="group" [contest]="contest" [myFan]="myFan" ></upload-code-contest-entry>
				</p>
			</div>
		</div>
	</div>
	<div class="col-xs-12">	
	  	<button
	      class="white-text btn btn-info btn-lg purple-gray-background" 
	      style="
	        margin: 20px auto;
	        border-width: 0px;
	        display: block;
	      " 
	      *ngIf="isGroupAdmin" 
	      type="button" 
	      (click)="openInviteModal()"
		>Share</button>
	</div>
	<div style="width: 100% ; margin-top : 10px; ">
		<div *ngIf="!noRewards">	
			<h4 style="text-align: center;">
				<img class="contest-thumbnail-margin" height="18" width="18"  src="/app/assets/images/trophy.png" />	
				<h4 
					class="inline-block competition-line-height  contest-thumbnail-margin no-margins no-padding" 
					*ngIf="contest.rewardCash > 0"
					style="
					    position: relative;
					    top: 3px;
				    " 	
				>1st {{ rewardCurrencySymbol }}{{ contest?.displayRewardCash }} </h4>
				<div *ngIf="!(contest.rewardCash > 0) && !contest.rewardOther" class="inline-block   contest-thumbnail-margin no-margins no-padding">
					<img class="contest-thumbnail-margin" height="18" width="18"  src="/app/assets/images/fanpoint.png" />
					<div class="inline-block rewards center-text contest-thumbnail-margin">
					<h4 
						class="inline-block competition-line-height contest-thumbnail-margin"
						style="
					    position: relative;
					    top: 3px;
					    "
				    >{{ contest?.rewardFanpoints }}</h4>
					</div>
				</div>
				<span style="position: relative; top: 3px;"> {{ contest?.rewardOther }}</span>
			</h4>
		</div>
	</div>

	<a *ngIf="!noRewards" (click)="showAllRewardsModal()" style="text-align: center;"><div><p>See All rewards</p></div></a>
	<div style="width: 100% ; margin-top : 10px; margin-bottom: 10px;">
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleDetails()">
				<div *ngIf="!showDetails" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/description-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Details</h4>
				</div>
				<div *ngIf="showDetails" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/description-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Details</h4>
				</div>
			</a>
		</div>
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleTimeline()">
				<div *ngIf="!showTimeline" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/timeline-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Timeline</h4>
				</div>
				<div *ngIf="showTimeline" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/timeline-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Timeline</h4>
				</div>
			</a>
		</div>
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleOtherRewards()">
				<div *ngIf="!showOtherRewards" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/trophy-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Rewards</h4>
				</div>
				<div *ngIf="showOtherRewards" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/trophy-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Rewards</h4>
				</div>
			</a>
		</div>
	</div>
	<div *ngIf="showTimeline" class="col-xs-12" style="padding: 0px;">
		<div class="center-text col-centered block col-xs-12">
			<div *ngIf="contest.earlyRegistration">
				<p>Pre-Registration 
					<span *ngIf="!preRegistrationEnded">Ends</span>
					<span *ngIf="preRegistrationEnded">Ended</span>
					 {{ displayEarlyRegistrationTime }}
					<span *ngIf="contest.earlyRegistration.registrationMax">
						: {{ contest.earlyRegistration.registrationTotal }}/{{ contest.earlyRegistration.registrationMax }} Registered
						<span style="color: red;" *ngIf="
							contest.earlyRegistration.registrationTotal >= contest.earlyRegistration.registrationMax
						"> SOLD OUT</span>
					</span>
				</p>
			</div>
			<div class="col-xs-12" *ngIf="contest.startTime">
				<p><span *ngIf="!contestStarted">Starts</span><span *ngIf="contestStarted">Started</span> {{ displayStartTime }}</p>
			</div>
			<div *ngIf="!contestEnded" class="col-xs-12">
				<p>
					Ends {{ contest?.formattedEndOfContest}}
					<span *ngIf="!contestIsFree">
						: {{ entryCurrencySymbol }}{{ entryPrice }} 
					</span> 
				</p>
			</div>
			<div class="col-xs-12" *ngIf="contestEnded">
				<p>
					Ended {{ contest?.formattedEndOfContest }}
					<span class="d-inline" *ngIf="!contestIsFree">
						: {{ entryCurrencySymbol }}{{ entryPrice }} 
					</span>
				</p>
			</div>
			<div class="col-xs-12" *ngIf="contest.votingEndTime">
				<p><span *ngIf="!votingEnded">Voting Ends</span><span *ngIf="votingEnded">Voting Ended</span> {{ displayVotingEndTime }}</p>
			</div>
			<div class="col-xs-12">
				<p>Winner Announced {{ contest?.formattedWinnerSelectedDate }}</p>
			</div>
		</div>
	</div>

	<div *ngIf="showDetails" class="col-xs-12">		
		<div 
			style="
				width : 100%; 
				display: block; 
				overflow: hidden;
    	"
    >
    	<div class="col-xs-12">
	    	<p *ngIf="contest.entryGoesToPot" style="margin: 10px;">
	    		Entry goes to the winning pot
	    	</p>
    	</div>
			<p 
				class="word-break block" 
				style="
					margin: 10px;
		    		margin-top: 0px;
				    white-space: pre-wrap;
		    	"
	    >{{ contest?.description }}</p>
	    	<update-contest-entry-privacy *ngIf="isGroupAdmin" [contest]="contest"></update-contest-entry-privacy>
			<div *ngIf="contest.entryPrivacy === 'private' && !isGroupAdmin" style="text-align: center; display: block; overflow: hidden;"><p>Entries are private</p></div>
			<div class="col-xs-offset-1 col-xs-11">
				<judge [contest]="contest" ></judge>
			</div>
			<div class="col-xs-offset-1 col-xs-11" *ngIf="contest.commenters && contest.commenters.length > 0">
				<commenter [contest]="contest" ></commenter>
			</div>
			<div *ngIf="contest.entryPrivacy == 'private' && contest.viewers && contest.viewers.length > 0" class="col-xs-offset-1 col-xs-11">
				<viewer [contest]="contest" ></viewer>
			</div>
			<div *ngIf="scoreCard" class="col-xs-10 col-xs-offset-1">
				<h3 style="text-align: center;">Judging Score Card</h3>
				<div *ngFor="let criteria of scoreCard.judgingCriteria">
					<p style="margin-bottom: 4px;"><span style="font-weight: 800;">{{ criteria.name }}</span> - Out of {{ criteria.score }} Points</p>
					<p style="margin-top: 4px;">{{ criteria.description }}</p>
				</div>
			</div>
			<div style="width : 100%; display: block; overflow: hidden;">
				<p *ngIf="contest.contestType == 'pictureGallery'" style="text-align: center;">Max Submissions: {{ contest.maxSubmissionsPerEntry }}</p>
			</div>
			<div style="width : 100%; display: block; overflow: hidden;">
				<p *ngIf="contest.maxVotes > 0" style="text-align: center;">{{ votingTypeText }}</p>
				<p *ngIf="contest.maxVotes > 0" style="text-align: center;">{{ contest.maxVotes }} <span *ngIf="contest.pricePerVote > 0">free</span> votes per voter.</p>
				<p *ngIf="convertedPricePerVote > 0" style="text-align: center;">{{entryCurrencySymbol}}{{ convertedPricePerVote }} per vote.</p>
			</div>
		</div>
	</div>
	<div *ngIf="contest.tags" style="width: 100%; display: block; overflow: hidden; padding: 10px;">
		<div
			style="
		    margin: 5px;
		    padding: 5px;
		    background-color: rgba(152, 140, 229, 0.6);
		    color: white;
		    border-radius: 10px;
		    text-align: center;
	    	display: inline-block;
	    " 	
			*ngFor="let tag of contest.tags" 
	  >
			<a 
				style="color: white;" 
				routerLink="/search/{{ tag }}"
			>{{ tag }}</a>
		</div>
	</div> 
	<!-- <div *ngIf="myGroup && !!!contest.descriptionVideo && !contest.ended" class="col-xs-offset-1">
		<contest-video-upload [contest]="contest"></contest-video-upload>
	</div> -->

	<div class="col-xs-offset-1 col-xs-10" *ngIf="alreadyEnteredContest"><p>You have already entered this competition.</p></div>

	<div id="contestRewardsModal" class="cssModal">
  		<div id="contestRewardsModalContent" class="cssModalContent darker-background modal-content row">
    		<div>
				<style>
					.md-datepicker-calendar-pane {
					    z-index: 1200  !important; 
					}
					.datePicker {	
					    z-index: 1200 !important;
					}
				</style>
            	<button type="button" class="btn btn-default" style="position: absolute; top: 0px; left : 0px;" (click)="hideAllRewardsModal()">Close</button>
      			<div class="modal-header">
      				<h2>Rewards</h2>
      			</div>
      			<div class="col-xs-offset-1">
					<div class="col-xs-10 col-xs-offset-1 block  contest-thumbnail-margin  no-margins no-padding">		
						<p class="competition-line-height  contest-thumbnail-margin no-margins no-padding col-xs-12" style="font-size: 24px;">
							{{ ordinal(1) }}
							<span *ngIf="contest.rewardCash > 0">{{ rewardCurrencySymbol }}{{ contest?.displayRewardCash }}</span>
							<span *ngIf="contest.totalPotSize > 0">
								<span *ngIf="contest.rewardCash > 0">
								 + 
								</span>
								{{ rewardCurrencySymbol }}{{ displayPotSize }} Pot
							</span> 
						</p>
						<!-- <div class="inline-block   contest-thumbnail-margin no-margins no-padding">
							<img class="contest-thumbnail-margin" height="14" width="14"  src="/app/assets/images/fanpoint.png" />
							<div class="inline-block rewards center-text contest-thumbnail-margin">
								<p class="inline-block competition-line-height contest-thumbnail-margin">{{ contest?.rewardFanpoints }}</p>
							</div>
						</div> -->
						<p class="col-xs-12" style="white-space: pre-wrap;">{{ contest?.rewardOther }}</p>
					</div>
	
					<div *ngFor="let reward of contest.rewards" class="col-xs-10 col-xs-offset-1 block  contest-thumbnail-margin  no-margins no-padding">		
						<p class="competition-line-height  contest-thumbnail-margin no-margins no-padding col-xs-12" style="font-size: 24px;"><span>{{ ordinal(reward.position) }} </span><span *ngIf="reward.rewardCash > 0">{{ rewardCurrencySymbol }}{{ reward.displayRewardCash }}</span>
						</p>
						<!-- <div class="inline-block   contest-thumbnail-margin no-margins no-padding">
							<img class="contest-thumbnail-margin" height="14" width="14"  src="/app/assets/images/fanpoint.png" />
							<div class="inline-block rewards center-text contest-thumbnail-margin">
								<p class="inline-block competition-line-height contest-thumbnail-margin">{{ reward.rewardFanpoints }}</p>
							</div>
						</div> -->
						<p class="col-xs-12" style="white-space: pre-wrap;">{{ reward.rewardOther }}</p>
					</div>
      			</div>
      		</div>
      	</div>
    </div>
<div class="show-on-phones" style="height: 60px;"><p style="font-size: 0px;">a</p></div>
<div *ngIf="!contest"> <p>Currently no contest found</p>
</div>
<div id="inviteModal" class="cssModal">
<div id="inviteModalContent" class="cssModalContent modal-content row">
  <button type="button" class="btn btn-default" (click)="closeInviteModal()">Close</button>
  <invite [contest]="contest" ></invite>
</div>
</div>