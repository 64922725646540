<div style="height: 100vh" class="row no-padding no-margins text-center col-centered">  
    <img class="col-centered" style="margin-top: 20px; " width="80" height="80" src="/app/assets/images/spotidolLogo-white.png">
    <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; width: 300px;" class="col-centered sans-font">
            <div *ngIf="!loggedIn">
                <h1>Reset Your Password</h1>
                <input class="roundBorders input-styling" type="text" [(ngModel)]="user" placeholder="Enter Email Here">
                <button type="submit" class="margin-bottom col-centered btn btn-md block no-padding no-margins purple-background medium-small-text white-text" style="padding: 6px;" (click)="resetPassword(user)">Reset</button>
                <div *ngIf="passwordReset">Success! Please check your email</div>
                <div *ngIf="!!err"> {{ err }} </div>
            </div>
            <div *ngIf="loggedIn"><p>You are already logged in! To update your password, <a routerLink="/updateUser">update your user</a></p></div>
    </div>
    <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; width: 300px;" class="col-centered bold">
        <a routerLink="/login"><span>Log In</span></a>
        <span> or </span>
        <a routerLink="/signUp"><span>Sign Up</span></a>
    </div>
    <div class="col-centered white-text" style="margin-top: 8px;"><a class="white-text" routerLink="/termsOfUse">Terms</a> - <a class="white-text" routerLink="/privacyPolicy">Privacy</a> - <a class="white-text">2025  &copy; Spotidol, Inc.</a></div>
</div>